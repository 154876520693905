/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import Page from "../common/Page";
import {H1} from "../common/typography/Headings";

// TODO: design error page
const NotFoundPage = () => (
    <Page lang="en" title="Atilika: ai + language" path="/error/">
        <H1>Not found</H1>
    </Page>
);

export default memo(NotFoundPage);
